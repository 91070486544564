import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import { Sidebar, LatLongMap } from '@/components';
import { countryArray, provinceArray, isNullOrWhiteSpace } from '@/utils';
import isServer from '@/utils/isServer';

if (!isServer) {
  const { extend } = require('vee-validate');
  const { required, email } = require('vee-validate/dist/rules');
  var VueTagsInput = require('@johmun/vue-tags-input').default;
  var { VueEditor } = require('vue2-editor/dist/vue2-editor.core');

  extend('required', {
    ...required,
    message: 'Dit veld is vereist.',
  });

  extend('email', {
    ...email,
    message: 'Een geldig e-mailadres is vereist.',
  });

  extend('protocol', {
    // eslint-disable-next-line no-unused-vars
    message: 'Url moet starten met http of https',
    validate: (value) => {
      // eslint-disable-next-line no-useless-escape
      const strongRegex = new RegExp(/^(http|https):/);
      return strongRegex.test(value);
    },
  });

  extend('coordinate', {
    // eslint-disable-next-line no-unused-vars
    message: 'Geef een geldig coördinaat in (Bv: xx.xxxxxx)',
    validate: (value) => {
      // eslint-disable-next-line no-useless-escape
      const strongRegex = new RegExp(/^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/);
      return strongRegex.test(value);
    },
  });

  extend('url', {
    // eslint-disable-next-line no-unused-vars
    message: 'Url kan geen "/", "?" of "." bevatten.',
    validate: (value) => !value.includes('?') && !value.includes('/') && !value.includes('.'),
  });

  var ImageUpload = require('vue-image-crop-upload').default;
}

export default {
  metaInfo: {
    title: 'Club formpage',
  },
  name: 'ClubForm',
  components: {
    [Sidebar.name]: Sidebar,
    VueEditor,
    LatLongMap,
    ImageUpload,
    VueTagsInput,
  },
  props: {},
  data: () => ({
    editMode: false,
    currentTag: '',
    tags: [],
    club: {
      name: '',
      abbreviation: '',
      contactPerson: '',
      address_Street: '',
      address_Nr: '',
      address_Postcode: '',
      address_City: '',
      address_Country: '',
      address_Province: '',
      email: '',
      phone: '',
      website: '',
      clubId: '',
      clubUrl: '',
      mollie: '',
      clubDisciplines: [],
      membershipInfo: '',
      membershipLink: '',
      membershipCallToAction: '',
      lat: null,
      long: null,
      aboutUs: '',
      currentUserIsAdmin: false,
      tags: [],
    },
    show: false,
    showCover: false,
    imageObj: '',
    coverImageObj: '',
    error: false,
    errorCover: false,
    loadCoordinateMap: false,
    existingMarker: null,
    disciplineErrorMessage: '',
    mapErrorMessage: '',
    editorToolbar: [['bold', 'link']],
    provinceArray: provinceArray.map((p) => ({ name: p })),
    countryArray: countryArray.map((c) => ({ name: c })),
    selectedProvince: { name: 'West - Vlaanderen' },
    selectedCountry: { name: 'België' },
    url: process.env.VUE_APP_URL_MANUAL,
  }),
  computed: {
    ...mapGetters('tag', {
      tagsList: 'getTagList',
    }),
    filteredItems() {
      return this.tagsList.filter(
        (i) => i.text.toLowerCase().indexOf(this.currentTag.toLowerCase()) !== -1,
      );
    },
    ...mapGetters('discipline', {
      disciplineList: 'getDisciplineList',
    }),
    ...mapGetters('club', {
      editClub: 'getClub',
      clubId: 'getClubId',
    }),
    formattedDisciplineList() {
      const formattedDisciplineList = [];

      this.disciplineList.forEach((discipline) => {
        if (discipline.level === 0) {
          const d = _.clone(discipline);
          formattedDisciplineList.push(d);
        }
      });
      return formattedDisciplineList;
    },
    infoEmail() {
      return process.env.VUE_APP_INFO_EMAIL_ADDRESS;
    },
  },
  methods: {
    ...mapActions('tag', {
      initialiseTags: 'FETCH_DATA_TAG_LIST',
    }),
    ...mapActions('club', {
      createAction: 'CREATE_CLUB',
      editAction: 'EDIT_CLUB',
      initialiseClub: 'FETCH_DATA_EDIT_CLUB',
    }),
    ...mapActions('discipline', {
      initialiseDisciplineList: 'FETCH_DATA_DISCIPLINE_LIST',
    }),
    ...mapActions('account', {
      initialiseUser: 'FETCH_DATA_EDIT_USER',
    }),
    tagsChanged(tags) {
      this.tags = tags.map((t) => ({ text: t.text }));
      this.club.tags = this.tags;
    },
    inputChanged(event, disciplineId) {
      if (event.srcElement.checked) {
        this.addDisciplineId(disciplineId);
      } else {
        this.removeDisciplineId(disciplineId);
      }
    },
    cropSuccess(imgDataUrl) {
      fetch(imgDataUrl)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], 'File name', { type: 'image/png' });
          this.club.image = file;
          this.club.hasPicture = true;
        })
        .then(() => {
          this.createImage(this.club.image);
          this.toggleShow();
        });
    },
    cropSuccessCover(imgDataUrl) {
      fetch(imgDataUrl)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], 'File name', { type: 'image/png' });
          this.club.coverImage = file;
          this.club.hasCoverPicture = true;
        })
        .then(() => {
          this.createCoverImage(this.club.coverImage);
          this.toggleShowCover();
        });
    },
    createImage(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.imageObj = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    createCoverImage(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.coverImageObj = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    toggleShow() {
      this.show = !this.show;
    },
    toggleShowCover() {
      this.showCover = !this.showCover;
    },
    save() {
      this.$refs.form.validate().then((success) => {
        if (!success) {
          setTimeout(() => {
            const errorKeys = Object.keys(this.$refs.form.errors);
            const errors = errorKeys.filter((e) => this.$refs.form.errors[e].length > 0);

            this.$refs.form.refs[errors[0]].$el.scrollIntoView();
            window.scrollBy(0, -75);
          }, 100);
        } else {
          if (isNullOrWhiteSpace(this.club.address_Country) || isNullOrWhiteSpace(this.club.address_Province)) {
            this.club.address_Province = this.selectedProvince.name;
            this.club.address_Country = this.selectedCountry.name;
          }
          if (this.club.clubDisciplines.length <= 0) {
            this.disciplineError = 'Minstens 1 categorie vereist.';
          } else if (this.club.lat == null) {
            this.mapErrorMessage = 'Gelieve een locatie aan te duiden op de map.';
          } else if (this.editMode) {
            this.updateClub();
          } else {
            this.createNewClub();
          }
        }
      });
    },
    cancel() {
      if (this.club.id === undefined) {
        this.$router.push({ name: 'clubs' });
      } else {
        this.$router.push({ name: 'club-detail', params: { cluburl: this.club.clubUrl } });
      }
    },

    addDisciplineId(id) {
      this.club.clubDisciplines.push(id);
      this.errorCheckClubDisciplines();
    },
    removeDisciplineId(id) {
      const index = this.club.clubDisciplines.indexOf(id);
      this.club.clubDisciplines.splice(index, 1);
      this.errorCheckClubDisciplines();
    },
    isSuperAdmin() {
      return !!(this.$isSuperAdmin);
    },
    isAdmin() {
      return !!(this.$isAdmin || this.club.currentUserIsAdmin);
    },
    fillClubDisciplineList() {
      this.club.clubDisciplines.forEach((clubDiscipline) => {
        this.formattedDisciplineList.find((discipline) => discipline.id === clubDiscipline).checked = true;
      });
    },
    createNewClub() {
      this.createAction(this.club)
        .then(() => {
          this.$router.push({ name: 'club-detail', params: { cluburl: this.club.clubUrl } });
        })
        .catch((error) => {
          this.$toasted.show(...error.response.data);
        });
    },
    updateClub() {
      this.editAction(this.club)
        .then(() => {
          this.$router.push({ name: 'club-detail', params: { cluburl: this.club.clubUrl } });
        })
        .catch((error) => {
          this.$toasted.show(...error.response.data);
        });
    },
    errorCheckClubDisciplines() {
      if (this.club.clubDisciplines.length <= 0) {
        this.disciplineErrorMessage = 'Minstens 1 categorie vereist.';
      } else {
        this.disciplineErrorMessage = '';
      }
    },
    newLatLong(newLatLong) {
      [this.club.lat, this.club.long] = newLatLong;
    },

    updateProvinceId(selectedProvince) {
      if (selectedProvince) {
        this.club.address_Province = selectedProvince.name;
      }
    },

    updateCountryId(selectedCountry) {
      if (selectedCountry) {
        this.club.address_Country = selectedCountry.name;
      }
    },
    setClubUrl() {
      if (!this.club.clubUrl) {
        this.$set(this.club, 'clubUrl', this.club.name);
      }
    },
  },
  created() {
    this.initialiseTags();
    this.initialiseUser().then(() => {
      if (this.$route.params.cluburl) {
        const paramClubUrl = this.$route.params.cluburl;
        this.editMode = true;
        this.initialiseDisciplineList().then(() => {
          this.initialiseClub(paramClubUrl).then(() => {
            this.imageObj = _.cloneDeep(this.editClub.profilePicture);
            this.coverImageObj = _.cloneDeep(this.editClub.coverPicture);
            this.club = _.cloneDeep(this.editClub);
            this.selectedProvince.name = this.club.address_Province;
            this.selectedCountry.name = this.club.address_Country;
            if (!this.isAdmin()) {
              this.$router.push({ name: '404' });
            }
            this.fillClubDisciplineList();
            this.existingMarker = {
              id: 1,
              latLong: [this.club.lat, this.club.long],
            };
            this.loadCoordinateMap = true;
            // Set existing tags:
            this.tags = this.club.clubTags.map((t) => ({ text: t.name }));
            this.club.tags = this.tags; // Update/Create model requires this format.
          });
        });
      } else if (!this.isSuperAdmin()) {
        this.$router.push({ name: '404' });
      } else this.initialiseDisciplineList();
    });
  },
};
